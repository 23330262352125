







































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { Contact, UpdateContactModel } from "@/api/models/contacts/contact";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import Categories from "@/api/models/enums/categoriesEnum";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "update-contact",
})
export default class EditContact extends Vue {
  @Prop({ required: true, type: Boolean })
  isUpdateContactSidebarActive!: boolean;
  @Prop({ required: true }) contactId!: string;

  loading = false;
  required = required;
  loadingContact = false;

  categories = Categories;
  contact: UpdateContactModel = {};

  async created() {
    await this.$http.myressif.fournisseurs
      .getContactById(this.$route.params.id, this.contactId)
      .then((res: Contact) => {
        this.contact = res;
        this.loadingContact = true;
      });
  }

  resetForm() {
    this.contact = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.fournisseurs
      .updateContactFournisseur(
        this.$route.params.id,
        this.contact.id,
        this.contact
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du contact",
            text: "Contact modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-contact");
        this.$emit("update:is-update-contact-sidebar-active", false);
        this.loading = false;
      });
  }
}
