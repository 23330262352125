





























































































































































































































































































































import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BIconPencilFill,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Fournisseur } from "@/api/models/fournisseurs/fournisseur";
import FournisseurListContacts from "./Contacts/Contacts.vue";
import FournisseurListContrats from "./Contrats/Contrat.vue";
import ListInformationsAdditionnelles from "../informationAdditionnelles/ListInformationsAdditionnelles.vue";
import FournisseurListAnalysesTactique from "./AnalysesTactique/AnalysesTactique.vue";
import { TypeEntiteEnum } from "@/api/models/enums/typeEntiteEnum";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    FournisseurListContacts,
    FournisseurListContrats,
    ListInformationsAdditionnelles,
    FournisseurListAnalysesTactique,
    BIconPencilFill,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class Editfournisseur extends Vue {
  fournisseur: Fournisseur | null = null;
  editFournisseur: Fournisseur | null = null;
  mainLoading = true;
  invalid = true;
  required = required;

  listTypesPrestations: any[] = [];
  totalCountTypePrestation = 0;

  listProcessusEvaluateurs: any[] = [];
  totalCountProcessusEvaluateurs = 0;

  entityType = TypeEntiteEnum.FOURNISSEUR;

  async created() {
    await this.loadTypesPrestation();
    await this.loadProcessusEvaluateurs();
    await this.getFournisseur();
  }

  async getFournisseur() {
    this.mainLoading = true;
    await this.$http.myressif.fournisseurs
      .getById(this.$route.params.id)
      .then((res: Fournisseur) => {
        this.fournisseur = { ...res };
        this.editFournisseur = { ...res };
      });
    this.mainLoading = false;
  }

  async loadTypesPrestation() {
    await this.$http.myressif.parametres
      .getByNom("type_prestation_fournisseur")
      .then((res: any) => {
        this.listTypesPrestations = res ? JSON.parse(res.valeur) : [];
      });
  }

  async loadProcessusEvaluateurs() {
    await this.$http.myressif.parametres
      .getByNom("processus_evaluateurs_fournisseur")
      .then((res: any) => {
        this.listProcessusEvaluateurs = res ? JSON.parse(res.valeur) : [];
      });
  }

  goToList() {
    this.$router.push({ name: "fournisseurs" });
  }

  async edit() {
    this.invalid = !this.invalid;
  }

  async reset() {
    this.editFournisseur = this.fournisseur;
    this.$set(this, "editFournisseur", this.fournisseur);
    this.invalid = true;
  }
  async askDeletefournisseur(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce fournisseur ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.fournisseurs.delete(id).then(
      async (response: string) => {
        successAlert.fire({
          title: "Suppression d'un fournisseur",
          text: "Suppression effectuée avec succès",
        });
        this.goToList();
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }

  async submit(editFournisseur: Fournisseur) {
    await this.$http.myressif.fournisseurs
      .update(this.$route.params.id, editFournisseur)
      .then(
        async (res: any) => {
          successAlert.fire({
            title: "Modification d'un fournisseur",
            text: "Modification effectuée avec succès",
          });
          await this.getFournisseur();
          this.reset();
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }
}
